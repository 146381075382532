import React, {
    useMemo,
    useState,
    useEffect,
    useContext,
    createContext,
    PropsWithChildren,
} from 'react'

import {
    AvailabilityHoursInput,
    ClinicFieldsFragment,
    useUpdateClinicMutation,
} from '~graphql/generated/graphql'
import { SetStateFn } from '~config/constants'
import { handleMergeClinicSettings } from '../helpers'
import { useToastFeedback } from '~utils/hooks/use-toast-feedback'
import { cleanTypenames } from '~utils/helpers'

export default function CommunicationSettingsProvider({
    children,
    clinic,
}: PropsWithChildren<{ clinic: ClinicFieldsFragment }>) {
    return (
        <CommunicationSettingsContext.Provider
            value={{ ...useCommunicationSettingsValues(clinic) }}
        >
            {children}
        </CommunicationSettingsContext.Provider>
    )
}

export function useCommunicationSettingsContext() {
    return useContext(CommunicationSettingsContext)
}

function useCommunicationSettingsValues(
    clinic: ClinicFieldsFragment,
): CommunicationSettingsContextType {
    const [useMsgAutoPriority, setUseMsgAutoPriority] = useState<boolean>(false)
    const [isAutoReplyEnabled, setIsAutoReplyEnabled] = useState<boolean>(false)
    const [autoReplyMessage, setAutoReplyMessage] = useState<string>('')
    const [autoReplyHours, setAutoReplyHours] =
        useState<AvailabilityHoursInput | null>(null)
    const [isWritebackEnabled, setIsWritebackEnabled] = useState<boolean>(false)
    const [shouldDailyWriteback, setShouldDailyWriteback] =
        useState<boolean>(false)
    const [usePdfForWriteback, setUsePdfForWriteback] = useState<boolean>(false)
    const [writebackBufferMinutes, setWritebackBufferMinutes] =
        useState<number>(-1)
    const [writebackBeforeApptMins, setWritebackBeforeApptMins] =
        useState<number>(-1)
    const [shouldWritebackOnSend, setShouldWritebackOnSend] =
        useState<boolean>(false)
    const [shouldWritebackOnReceive, setShouldWritebackOnReceive] =
        useState<boolean>(false)
    const [shouldWritebackNotifs, setShouldWritebackNotifs] =
        useState<boolean>(false)
    const [shouldUseAiWriteback, setShouldUseAiWriteback] =
        useState<boolean>(false)

    useEffect(() => {
        if (!clinic.settings) return
        const { settings } = clinic

        setUseMsgAutoPriority(settings.msgs_auto_priority ?? false)
        setIsAutoReplyEnabled(settings.auto_reply_enabled ?? false)
        setAutoReplyMessage(settings.auto_reply_message ?? '')
        setAutoReplyHours(settings.auto_reply_hours ?? null)
        setIsWritebackEnabled(settings.comms_writeback_enabled ?? false)
        setShouldDailyWriteback(settings.comms_should_daily_writeback ?? false)
        setUsePdfForWriteback(settings.comms_use_pdf_for_writeback ?? false)
        setWritebackBufferMinutes(settings.comms_writeback_buffer_minutes ?? -1)
        setWritebackBeforeApptMins(
            settings.comms_writeback_before_appt_mins ?? -1,
        )
        setShouldWritebackOnSend(
            settings.comms_should_writeback_on_send ?? false,
        )
        setShouldWritebackOnReceive(
            settings.comms_should_writeback_on_receive ?? false,
        )
        setShouldWritebackNotifs(
            settings.comms_should_writeback_notifs ?? false,
        )
        setShouldUseAiWriteback(settings.comms_should_use_ai_writeback ?? false)
    }, [clinic])

    const canSave = useMemo(() => {
        if (!clinic.settings) return false
        const { settings } = clinic

        return (
            useMsgAutoPriority !== settings.msgs_auto_priority ||
            isAutoReplyEnabled !== settings.auto_reply_enabled ||
            autoReplyMessage !== settings.auto_reply_message ||
            autoReplyHours !== settings.auto_reply_hours ||
            isWritebackEnabled !== settings.comms_writeback_enabled ||
            shouldDailyWriteback !== settings.comms_should_daily_writeback ||
            usePdfForWriteback !== settings.comms_use_pdf_for_writeback ||
            writebackBufferMinutes !==
                settings.comms_writeback_buffer_minutes ||
            writebackBeforeApptMins !==
                settings.comms_writeback_before_appt_mins ||
            shouldWritebackOnSend !== settings.comms_should_writeback_on_send ||
            shouldWritebackOnReceive !==
                settings.comms_should_writeback_on_receive ||
            shouldWritebackNotifs !== settings.comms_should_writeback_notifs ||
            shouldUseAiWriteback !== settings.comms_should_use_ai_writeback
        )
    }, [
        clinic,
        useMsgAutoPriority,
        isAutoReplyEnabled,
        autoReplyMessage,
        autoReplyHours,
        isWritebackEnabled,
        shouldDailyWriteback,
        usePdfForWriteback,
        writebackBufferMinutes,
        writebackBeforeApptMins,
        shouldWritebackOnSend,
        shouldWritebackOnReceive,
        shouldWritebackNotifs,
        shouldUseAiWriteback,
    ])

    const toasts = useToastFeedback(
        'Successfully updated settings',
        'Error updating settings',
    )

    const [handleUpdate, { loading }] = useUpdateClinicMutation({
        variables: {
            id: clinic?.id || '',
            data: {
                settings: {
                    msgs_auto_priority: useMsgAutoPriority,
                    auto_reply_enabled: isAutoReplyEnabled,
                    auto_reply_message: autoReplyMessage,
                    auto_reply_hours: autoReplyHours
                        ? cleanTypenames(autoReplyHours)
                        : null,
                    comms_writeback_enabled: isWritebackEnabled,
                    comms_should_daily_writeback: shouldDailyWriteback,
                    comms_use_pdf_for_writeback: usePdfForWriteback,
                    comms_writeback_buffer_minutes: writebackBufferMinutes,
                    comms_writeback_before_appt_mins: writebackBeforeApptMins,
                    comms_should_writeback_on_send: shouldWritebackOnSend,
                    comms_should_writeback_on_receive: shouldWritebackOnReceive,
                    comms_should_writeback_notifs: shouldWritebackNotifs,
                    comms_should_use_ai_writeback: shouldUseAiWriteback,
                },
            },
        },
        update: (cache, res) => {
            handleMergeClinicSettings(clinic, cache, res)
        },
        ...toasts,
    })

    return {
        // Context Values
        canSave,
        isUpdateLoading: loading,

        // Actions
        handleUpdateSettings: handleUpdate,

        // Setting Values
        useMsgAutoPriority,
        setUseMsgAutoPriority,
        isAutoReplyEnabled,
        setIsAutoReplyEnabled,
        autoReplyMessage,
        setAutoReplyMessage,
        autoReplyHours,
        setAutoReplyHours,
        isWritebackEnabled,
        setIsWritebackEnabled,
        shouldDailyWriteback,
        setShouldDailyWriteback,
        usePdfForWriteback,
        setUsePdfForWriteback,
        writebackBufferMinutes,
        setWritebackBufferMinutes,
        writebackBeforeApptMins,
        setWritebackBeforeApptMins,
        shouldWritebackOnSend,
        setShouldWritebackOnSend,
        shouldWritebackOnReceive,
        setShouldWritebackOnReceive,
        shouldWritebackNotifs,
        setShouldWritebackNotifs,
        shouldUseAiWriteback,
        setShouldUseAiWriteback,
    }
}

type CommunicationSettingsContextType = {
    // Context Values
    canSave: boolean
    isUpdateLoading: boolean

    // Actions
    handleUpdateSettings: () => Promise<any>

    // Setting Values
    useMsgAutoPriority: boolean
    setUseMsgAutoPriority: SetStateFn<boolean>
    isAutoReplyEnabled: boolean
    setIsAutoReplyEnabled: SetStateFn<boolean>
    autoReplyMessage: string
    setAutoReplyMessage: SetStateFn<string>
    autoReplyHours: AvailabilityHoursInput | null
    setAutoReplyHours: SetStateFn<AvailabilityHoursInput | null>
    isWritebackEnabled: boolean
    setIsWritebackEnabled: SetStateFn<boolean>
    shouldDailyWriteback: boolean
    setShouldDailyWriteback: SetStateFn<boolean>
    usePdfForWriteback: boolean
    setUsePdfForWriteback: SetStateFn<boolean>
    writebackBufferMinutes: number
    setWritebackBufferMinutes: SetStateFn<number>
    writebackBeforeApptMins: number
    setWritebackBeforeApptMins: SetStateFn<number>
    shouldWritebackOnSend: boolean
    setShouldWritebackOnSend: SetStateFn<boolean>
    shouldWritebackOnReceive: boolean
    setShouldWritebackOnReceive: SetStateFn<boolean>
    shouldWritebackNotifs: boolean
    setShouldWritebackNotifs: SetStateFn<boolean>
    shouldUseAiWriteback: boolean
    setShouldUseAiWriteback: SetStateFn<boolean>
}

const CommunicationSettingsContext =
    createContext<CommunicationSettingsContextType>(
        {} as CommunicationSettingsContextType,
    )
